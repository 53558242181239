<template>
  <div>
  <b-row>
    <b-col class="mt-2">
      <b-button  @click="showFilters = !showFilters"  variant="flat-secondary" class="float-right">
        <iconBase iconName="filter_asc" :width="18" :height="18" iconColor="#626262" class="align-middle mr-2"><iconFilter/></iconBase>
        <span v-show="!showFilters"  class="font-bold">{{$t('Labels.ShowFilters')}}</span>
        <span v-show="showFilters"  class="font-bold">{{$t('Labels.HideFilters')}}</span>
      </b-button>
    </b-col>
  </b-row>
  <b-row v-show="showFilters">
    <!--<b-col sm="auto" cols="3">
      <b-form-group>
        <label class="font-bold" for="inputImei">{{$t('Labels.IMEI')}}</label>
        <b-form-input v-model="filters.imei" id="inputImei"/>
      </b-form-group>
    </b-col>-->
    <b-col sm="auto" cols="3">
      <b-form-group>
        <label class="font-bold" or="inputAlias">{{$t('Labels.Alias')}}</label>
        <b-form-input v-model="filters.name" id="inputAlias"/>
      </b-form-group>
    </b-col>
    <b-col sm="auto" cols="3" class="text-center container-btn-filters">
      <b-button @click="doFilter()" variant="primary" class="mr-2">{{$t('Labels.Filter')}}</b-button>
      <b-button @click="resetFilters()" variant="outline-danger">{{$t('Labels.Clean')}}</b-button>
    </b-col>
  </b-row>
  <b-row>
    <b-col>
      <b-button  @click="showAssign = !showAssign"  variant="flat-secondary" class="float-right">
        <feather-icon icon="PlusIcon" size="20" class="align-middle mr-2"/>
        <span v-show="!showAssign"  class="font-bold">{{$t('Labels.ShowAdd')}}</span>
        <span v-show="showAssign"  class="font-bold">{{$t('Labels.HideAdd')}}</span>
      </b-button>
    </b-col>
  </b-row>
  <b-row v-show="showAssign">
    <b-col sm="auto" cols="4">
      <b-form-group>
        <label class="font-bold" or="uuid">{{$t('Labels.TrackerUuid')}}</label>
        <cleave
          id="uuid"
          v-model="newUuid"
          class="form-control"
          :raw="false"
          :options="optionsTrackerUuid"
          placeholder=""
        />
      </b-form-group>
    </b-col>
    <b-col sm="auto" cols="4" v-if="showPin">
      <b-form-group>
        <label class="font-bold" or="pin">{{$t('Labels.PIN')}}</label>
        <b-form-input
          id="pin"
          v-model="pin"
          placeholder="000000"
          v-mask="mask"
        />
      </b-form-group>
    </b-col>
    <b-col sm="auto" cols="3" class="text-center container-btn-filters" v-if="!showPin">
      <b-button @click="assign()" variant="primary" class="mr-2" :disabled="newUuid.length === 0">{{$t('Labels.Add')}}</b-button>
    </b-col>
    <b-col sm="auto" cols="3" class="text-center container-btn-filters" v-if="!showPin">
      <b-button @click="showPin = true" variant="primary" class="mr-2" >Tengo un PIN</b-button>
    </b-col>
    <b-col sm="auto" cols="3" class="text-center container-btn-filters" v-else>
      <b-button @click="transfer()" variant="primary" class="mr-2" :disabled="newUuid.length === 0 || pin.length === 0">{{$t('Labels.Transfer')}}</b-button>
    </b-col>
  </b-row>
  <b-row class="container-filters-default">
      <b-col sm="12" md="auto" class="text-center my-2">
        <label>{{$t('Labels.OrderBy')}}</label>
        <v-select @input="getResponse" class="align-middle inline-block" :options="columnOrders" v-model="filters.byColumn" :reduce="v => v.value"  :clearable="false"/>
      </b-col>
      <b-col sm="12" md="auto" class="text-center my-2">
        <b-button size="sm" @click="filterOrders('ASC')"  variant="flat-secondary" class="btn-order" :class="{'bg-primary': filters.byOrder == 'ASC'}">
          <iconBase iconName="filter_asc" :width="18" :height="18" iconColor="#626262" class="align-middle"><iconFilterAsc/></iconBase>
        </b-button>
        <b-button  size="sm" @click="filterOrders('DESC')" variant="flat-secondary" class="btn-order" :class="{'bg-primary': filters.byOrder != 'ASC'}">
          <iconBase iconName="filter_desc" :width="18" :height="18" iconColor="#626262" class="align-middle"><iconFilterDesc/></iconBase>
        </b-button>
      </b-col>
      <b-col sm="12" md="auto" class="text-center my-2">
        <label>{{$t('Labels.ByPage')}}</label>
        <v-select @input="getResponse" class="align-middle inline-block" :options="byPage" v-model="filters.limit" :reduce="v => v.value"  :clearable="false"/>
      </b-col>
      <b-col sm="12" md="auto" class="text-center my-2">
        <a v-if="urlDownload.length > 0" class="btn btn-order btn-flat-secondary btn-sm text-primary align-middle" :href="urlDownload" download="Tracker.csv">
          <feather-icon icon="DownloadCloudIcon" size="20" />
        </a>
      </b-col>
  </b-row>
    <div id="data-list-list-view" class="data-list-container mt-2 px-">
      <b-table hover :fields="fields" :items="response" selectable select-mode="single" @row-selected="goToDetail" :busy="isBusy" :responsive="true" thead-class="d-none" tbody-tr-class="shadow-sm" table-variant="light">
        <template #cell(name)="item">
          <listItem
            :value="item.value"
            :description="$t('Labels.Alias')"
            img="pointtracker"
          />
        </template>
        <template #cell(deveui)="item">
          <listItem
            :value="item.value"
            :description="$t('Labels.DevEui')"
            img="alias"
          />
        </template>
        <template #cell(lat)="item">
          <listItem
            :value="item.value"
            :description="$t('Labels.Latitude')"
            img="georeference"
          />
        </template>
        <template #cell(long)="item">
          <listItem
            :value="item.value"
            :description="$t('Labels.Longitude')"
            img="georeference"
          />
        </template>
        <template #cell(battery)="item">
          <listItem
            :value="batteryCalculate(item.value)"
            :description="$t('Labels.Battery')"
            img="battery"
            unit="%"
          />
        </template>
        <template #cell(tipo_alerta)="item">
          <alertTracker :status="item.value" />
        </template>
        <template #cell(updated_at)="item">
          <listItem
            :value="item.value.split(' ')[0]"
            :description="item.value.split(' ')[1]"
            img="fecha"
          />
        </template>
      </b-table>
      <b-pagination v-if="response.length > 0" :per-page="filters.limit" :total-rows="totalRows" v-model="currentx" align="right"/>
    </div>
    <div v-if="response.length == 0" class="noData">{{$t('Messages.NoData')}}</div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import request from '@/libs/request/index'
import listItem from '@core/spore-components/listItem/listItem.vue'
import iconBase from '@core/spore-components/icons/iconBase'
import iconFilterAsc from '@core/spore-components/icons/iconFilterAsc'
import iconFilterDesc from '@core/spore-components/icons/iconFilterDesc'
import iconFilter from '@core/spore-components/icons/iconFilter'
import alertTracker from '@core/spore-components/alertTracker/alertTracker.vue'
import { batteryCalculate, getAlertMessage } from '@/libs/tools/helpers'
import {
  BButton,
  BRow,
  BCol,
  BTable,
  BPagination,
  BFormInput,
  BFormGroup
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'

const DATA = {
  code: 200,
  data: {
    count: 2,
    rows: [
      {
        uuid: 'cdfbacef-3540-41b5-aae3-448bcd330df4',
        deveui: '0011223344556677',
        serial: '1111111',
        imei: '1111111',
        alias: 'tracker 1',
        status: '1',
        latlong: '20.6738686,-103.3704324',
        created_at: '2021-08-20 17:29:11',
        updated_at: '2021-08-23 11:08:06'
      },
      {
        uuid: 'cdfbacef-3540-41b5-aae3-448bcd450df4',
        deveui: '7766554433221100',
        serial: '222222',
        imei: '222222',
        alias: 'tracker 2',
        status: '0',
        latlong: '20.6738686,-103.3704345',
        created_at: '2021-08-20 17:29:11',
        updated_at: '2021-08-23 11:08:06'
      }
    ]
  },
  msg: 'Ok'
}

export default {
  components: {
    'v-select': vSelect,
    listItem,
    iconBase,
    iconFilterAsc,
    iconFilterDesc,
    iconFilter,
    BButton,
    BRow,
    BCol,
    BTable,
    BPagination,
    BFormInput,
    BFormGroup,
    alertTracker,
    Cleave
  },
  data () {
    return {
      response: [],
      currentx: 1,
      totalRows: 0,
      filters: {
        limit: 20,
        offset: 0,
        byColumn: 'updated_at',
        byOrder: 'ASC',
        imei: '',
        name: ''
      },
      urlDownload: '',
      showFilters: false,
      showAssign: false,
      columnOrders: [
        { label: this.$t('Labels.Alias'), value: 'alias' },
        { label: this.$t('Labels.Date'), value: 'updated_at' }
      ],
      byPage: [
        { label: 20, value: 20 },
        { label: 50, value: 50 },
        { label: 75, value: 75 },
        { label: 100, value: 100 }
      ],
      data: DATA,
      fields: [
        { key: 'name', label: this.$t('Labels.Alias') },
        { key: 'deveui', label: 'DEVEUI' },
        /* { key: 'lat', label: this.$t('Labels.Latitude') },
        { key: 'long', label: this.$t('Labels.Longitude') }, */
        { key: 'battery', label: this.$t('Labels.Battery') },
        { key: 'tipo_alerta', label: this.$t('Labels.State') },
        { key: 'updated_at', label: this.$t('Labels.Date') }
      ],
      isBusy: true,
      batteryCalculate,
      newUuid: '',
      optionsTrackerUuid: {
        delimiter: '-',
        blocks: [8, 4, 4, 4, 12]
      },
      showPin: false,
      optionsPIN: {
        numericOnly: true
      },
      mask: '###-###'
    }
  },

  methods: {
    async getResponse () {
      this.isBusy = true
      const params = {
        url: 'trackers_admin',
        method: 'GET',
        params: this.filters
      }
      await request(params).then(response => {
        this.response = response.data.data.rows
        this.totalRows = response.data.data.count
        this.createCsv()
      }).catch(() => {
        this.response = []
      }).finally(() => {
        this.isBusy = false
      })
    },

    goToDetail (data) {
      this.$router.push(`/tracker/detail/${data[0].uuid}`)
    },

    createCsv () {
      const csv = []
      const headers = []
      headers.push(this.$t('Labels.Alias'))
      headers.push(this.$t('Labels.DevEui'))
      headers.push(this.$t('Labels.Latitude'))
      headers.push(this.$t('Labels.Longitude'))
      headers.push(this.$t('Labels.Battery'))
      headers.push(this.$t('Labels.Status'))
      headers.push(this.$t('Labels.Date'))
      csv.push(headers.join(','))
      this.response.map(item => {
        const row = []
        row.push(item.name)
        row.push(item.deveui)
        row.push(item.lat)
        row.push(item.long)
        row.push(batteryCalculate(item.battery) + '%')
        row.push(getAlertMessage(item.tipo_alerta))
        row.push(item.updated_at)
        csv.push(row.join(','))
        return item
      })
      const file = csv.join('\n')
      const csvFile = new Blob([file], { type: 'text/csv' })
      this.urlDownload = window.URL.createObjectURL(csvFile)
    },
    resetFilters () {
      this.filters.name = ''
      this.filters.imei = ''
    },
    doFilter () {
      if (this.currentx !== 1) { this.currentx = 1 } else { this.getResponse() }
    },
    filterOrders (param) {
      this.filters.byOrder = param
      this.getResponse()
    },
    async assign () {
      const params = {
        url: 'tracker/device/assign',
        method: 'POST',
        customMessages: true,
        params: {
          tracker_uuid: this.newUuid
        }
      }
      await request(params).then(response => {
        if (response.data.data === 'Assing') {
          this.newUuid = ''
          this.$swal({
            title: this.$t('Messages.Success'),
            text: this.$t('Messages.AssignSuccess'),
            timer: 5000,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-success'
            },
            buttonsStyling: false
          })
          this.getResponse()
        }
      }).catch(() => {
        this.$swal({
          title: this.$t('Messages.Fail'),
          text: this.$t('Messages.PINRequired'),
          timer: 5000,
          icon: 'info',
          customClass: {
            confirmButton: 'btn btn-danger'
          },
          buttonsStyling: false
        })
      })
    },
    async transfer () {
      const params = {
        url: 'tracker/device/assign',
        method: 'PUT',
        customMessages: true,
        params: {
          tracker_uuid: this.newUuid,
          pin: this.pin
        }
      }
      await request(params).then(response => {
        this.showPin = false
        this.pin = ''
        this.newUuid = ''
        this.$swal({
          title: this.$t('Messages.Success'),
          text: this.$t('Messages.AssignSuccess'),
          timer: 5000,
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-success'
          },
          buttonsStyling: false
        })
        this.getResponse()
      }).catch(() => {
        this.$swal({
          title: this.$t('Messages.Fail'),
          text: this.$t('Messages.FailMessage'),
          timer: 5000,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-danger'
          },
          buttonsStyling: false
        })
      })
    }
  },

  created () {
    this.getResponse()
  },

  watch: {
    currentx (val) {
      this.filters.offset = (val - 1) * this.filters.limit
      this.getResponse()
    },
    byColumn (val) {
      this.filters.byColumn = val
      this.currentx = 1
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "@/assets/scss/pages/List.scss";
</style>
